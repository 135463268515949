@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import "~swiper/modules/navigation/navigation.min.css";
@import "~swiper/modules/pagination/pagination.min.css";
@import "./variables.scss";
@import "./reset.scss";
@import "./common.scss";
@import "./animations.scss";
@import "@phosphor-icons/web/regular";
@import "@phosphor-icons/web/fill";
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  touch-action: pan-x pan-y;
  overflow-x: hidden;
  scroll-behavior: smooth;
  color: $color-black;
  font-family: "Inter", sans-serif;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ph,
.ph-bold{
  font-size: 20px;
  vertical-align: bottom;
}

//text and bg stylings
.text-yellow {
  color: $yellow;
}
.bg-black {
  background-color: black;
}
.text-black {
  color: #020202;
}
.text-white {
  color: #f3f5f4;
}
.text-red {
  color: red;
}

.form-control, .custom-select{
  &:focus{
    outline: none;
    box-shadow: none;
    border-color: #000!important;
  }
}
//ng-select stylings
.ng-select,
.ng-select-container,
.ng-select-container:hover {
  box-shadow: none !important;
  border: none !important;
}

.ng-option {
  padding: 15px 10px !important;  
}
.ng-option:last-child{
  border: none !important;
}
.ng-option-marked {
  background-color: #303339 !important;
  color: #FFFFFF !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}




.ng-input>input {
  // padding: 20px !important;
  padding: 10px !important;

}
.ng-placeholder{
  padding: 10px !important;
}
.ng-value {
  background-color: #ECECEC !important;
  color: #32353B !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 20px;
  margin: 10px;
  display: flex ;
  flex-direction: row-reverse;
  .ng-value-icon {
    padding: 0px 5px;
  }


}

.ng-value-container {
  background-color: #fff;
}
.ng-value-icon.left{
  border: none !important; 
}

//swal stylings
.swal2-container{
  z-index: 9000000 !important ;
}
.swal2-title{
  margin: 0px !important;
font-size: 16px !important;
font-weight: 500 !important;
color:#000 !important;
padding: 0rem 1.5rem 0rem 0rem !important;
&.link{
  padding:0.25rem !important;
}

}
.swal-text-link{
  color: #FF3567;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;

}
.swal2-html-container{
  margin: 0px !important;

}
.swal2-icon-show{
  display: flex;
  flex-direction: row !important;
  justify-content: end !important;
  align-items: flex-start !important;
  align-self: flex-start !important;
}
.swal2-close{
  position: absolute !important;
  top: -10px !important;
  color:#000 !important;
  
}
a {
  &:hover {
    text-decoration: none;
    color: #000;
  }
}


//swipper stylings
.swiper {
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
}
.swiper-pagination {
  padding: 2rem 0rem 0rem 0rem;
  position: static !important;
  text-align: left !important;
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  text-align: center;
  width: 50px !important;
  height: 50px !important;
  background-color: #1a1d22;
  color: white !important;

  &:after {
    font-size: 20px !important;
  }
}
.swiper-pagination-bullet {
  width: 11px !important;
  height: 11px !important;
  background-color: #ffffff !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background-color: #d9d9d9;
  width: 13px !important;
  width: 33px !important;
  height: 11px !important;
  border-radius: 17px !important;
}
.swiper-button-disabled {
  opacity: 0 !important;
}

//button stylings
.btn-white {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 275px;
  max-width: 100%;
  height: 40px;
  padding: 8px 16px;
  background-color: #fff;
  border: 0;
  color: $color-black;
  border-radius: 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  &.btn-ic {
    width: 40px;
    padding: 0;
    line-height: 40px;
    border-radius: 20px;
    font-size: 18px;
    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
    &.btn-circle {
      width: 45px;
      @media screen and (max-width: 767px) {
        width: 50px;
      }
    }
  }

  &.shadowed {
    box-shadow: 0px 4px 4px 0px #00000040 !important;
  }
  &.btn-ic-sq {
    width: 40px;
    padding: 0;
    line-height: 40px;
    font-size: 18px;
    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
    &:hover{
      transform: scale(1.2);
    }
  }
  &.width-auto {
    width: auto;
  }
}
.btn-pink {
  font-size: 15px;
  font-weight: 400;
  padding: 14px 40px;
  border-radius: 0;
  border: 0;
  line-height: 1;
  background-color: $brand-pink-color;
  color: #fff;
  transition: all 0.2s ease-in-out;
  i {
    vertical-align: middle;
  }
  &:hover{
    color: #fff;
    transform: scale(1.05);
  }
}
.btn-black {
  font-size: 12px;
  font-weight: 400;
  padding: 10px 30px;
  border-radius: 0;
  border: 0;
  line-height: 1;
  background-color: #000;
  color: #fff;
  img {
    display: inline-block;
    width: 15px;
    margin: 0 4px 0 0;
    vertical-align: middle;
  }
  i {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px 0 0;
    font-size: 15px;
  }
  &:hover {
    color: #fff;
  }
  @media screen and (max-width:767px){
    padding: 7px 25px;
    font-size: 14px;
 }
}
.btn-yellow {
  font-weight: 400;
  padding: 10px 30px;
  border-radius: 0;
  border: 0;
  line-height: 1;
  background-color: #ffda2a;
  color: #000;
  font-size: 18px;
  font-weight: 600;

  img {
    display: inline-block;
    width: 15px;
    margin: 0 4px 0 0;
    vertical-align: middle;
  }
  i {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px 0 0;
    font-size: 18px;
  }
  @media screen and (max-width:767px){
     padding: 7px 25px;
     font-size: 14px;
  }
  &.btn-small{
    font-size: 14px;
  }
  
}
.btn-light-yellow {
  font-weight: 400;
  padding: 20px 30px;
  border-radius: 0;
  border: 0;
  line-height: 1;
  background-color: #F4F058;
  color: #000;
  font-size: 16px;
  font-weight: 600;


  @media screen and (max-width:767px){
     padding: 10px 25px;
     font-size: 12px;
  }
  &.btn-small{
    font-size: 14px;
  }
  
}
img {
  vertical-align: bottom;
}
.page-breadcumb {
  margin: 0 0 1rem;
  .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin: 0;
    .breadcrumb-item {
      font-size: 12px;
      color: #ffffff8b;
      text-transform: uppercase;
      &.active {
        color: #fff;
      }
      a {
        color: #ffffff90;
      }
    }
    .breadcrumb-item + .breadcrumb-item {
      padding-left: 4px;
      &::before {
        padding-right: 4px;
      }
    }
  }
}

.popup-hoopr-icon{
	height: 36px;
	object-fit: contain;
	margin-bottom: 15px;
}

.global-share-container {
  .sb-group {
    margin-top: 0px !important;
    justify-content: center;
  }

  .sb-button {
    // background-color: $dropdown-bg !important;
    border-radius: 50% !important;
    height: 50px !important;
    width: 50px !important;
    margin: 3px;

    button {
      margin: 0px;
      height: 100%;
      width: 100%;
    }
    .sb-icon{
      font-size: 1.7em;
    }
  }

  .share-buttons {
    .sb-content {
      background-color: $dropdown-bg !important;
      border-radius: 50% !important;
      height: 50px !important;
      width: 50px !important;
    }
  }
}

.ngx-slider {
  margin: 0px 0 15px !important;
  // position: absolute !important;

  .ngx-slider-bar {
    background: #D9D9D9 !important;
  }

  .ngx-slider-selection {
    z-index: 2;
    background: #E3C328 !important;
    border-radius: 2px;
  }

  .ngx-slider-pointer {
    // display: none !important;
    height: 0px !important;
    width: 8px !important;

    &::after {
      left: 0px !important;
      background-color:#E3C328 !important;
      height: 9px !important;
      width: 9px !important;
    }
  }
}

.ngx-slider .ngx-slider-bar-wrapper {
  height: auto !important;
}

.ngx-slider.animate .ngx-slider-bubble.ngx-slider-combined {
  display: none !important;
}

.ngx-slider .ngx-slider-bubble {
  display: none !important;
}

.badge-black {
  display: inline-block;
  padding: 10px 30px;
  background-color: #000;
  color: #fff;
  font-size: 15px;
  white-space: nowrap;
  font-family: $ralewayFont;
  font-weight: 700;

  i,
  svg {
    vertical-align: text-bottom;
  }
}
.badge-yellow {
  display: inline-block;
  padding: 4px;
  background-color: #ffc700;
  color: #fff;
  font-size: 15px;
  white-space: nowrap;
  text-transform: uppercase;
  i,
  svg {
    color: #ffff;
    vertical-align: bottom;
  }
  &.badge-icon {
    padding: 2px;
    font-size: 12px;
  }
  
}
.badge-transparent {
  display: inline-block;
  padding: 8px 15px;
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  white-space: nowrap;
  border: 1px solid #ffff;
  i,
  svg {
    color: #ffff;
    vertical-align: bottom;
  }
}
.badge {
  position: absolute;
  top: 0;
  right: 0;
  width:40%;
  &.badge-bottom {
    top: auto;
    bottom: 0;
    left: 0;
    right: auto;
    width:35%;
    padding: 0px;
  }
  &.badge-bottom-playlist{
    top: auto;
    bottom: 0;
    left: 0;
    right: auto;
    width:20%;
    padding: 0px;
    &.w-10{
      width: 10%;
    }

  }
  &.badge-bottom-left{
    top: auto;
    bottom: 50px;
    left: 0;
    right: auto;
    padding: 0px;
  }
}
.frame-badge{
  width: 100%;
  height: auto;
}
.frame-badge-small{
  width: 60%;
}
.frame-badge-medium{
  width: 180%;
}
.frame-badge-large{
  width: 200%;
}

.trackImg {
  width: 100%;
  aspect-ratio: 1/1;
}

.container {
  @media only screen and (min-width: 1400px) {
    max-width: 1280px;
  }
}
.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.home-banner,
.signed-home-banner {
  .swiper {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .swiper-slide {
    width: 80%;
  }
  .swiper-pagination {
    padding: 1rem 0rem;
    position: static;
    text-align: center !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    background-color: gray !important;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    width: 11px !important;
    height: 11px !important;
    background-color: #000000 !important;
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
}
//signup and otp page
.sub-text {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #828282;

  span {
    color: #000;
  }
}
.policy-text {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #828282;
  line-height: 16px;
  
  span {
    color: #000;
    cursor: pointer;
  }
}
.head-text {
  color: $color-black;
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  font-family: $ralewayFont;
  img {
    vertical-align: baseline;
  }
}
.signup-img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.signup-btn {
  width: 100%;
  color: $color-white;
  font-size: 20px;
  background-color: $color-black;
  font-weight: 600;
  border: 0px;
  cursor: pointer;
}
//-------signup and otp page end


//playlists,movies and genres page
.title {
  font-size: 36px;
  font-weight: 700;
  color: #1c1b1a;
  font-family: $ralewayFont;
}

.view-all-text {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.045em;
  text-transform: uppercase;
  color: #373737;
  font-family: $ralewayFont;
  line-height: 1;
  &.view-all-white {
    color: #fff;
  }
  i {
    color: #000000;
    vertical-align: middle;
    font-weight: 800;
  }
}
.heading {
  font-size: 54px;
  font-weight: 700;
  text-align: center;
  color: #1c1b1a;
  font-family: $ralewayFont;
}
.name-text {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.045em;
  font-family: $ralewayFont;
  color: #1c1b1a;
  @include nowrap;
}
.search-text {
  font-family: $ralewayFont;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}

//trending and popular page
.trending {
  .trending-title {
    padding-top: 40px;
    font-family: $ralewayFont;
    font-size: 76px;
    font-weight: 700;
    i {
      font-size: 76px;
      vertical-align: middle;
    }
    img {
      width: 67px;
      vertical-align: baseline;
    }
  }
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: #353636;
  }
}

.individual{
  .track{
    @media only screen and (max-width: 1200px){
      .track-info{
        max-width: initial!important;
      }
      .track-moods{
        display: none!important;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .trending-title {
    font-size: 60px;
    i {
      font-size: 60px;
    }
    img {
      width: 60px;
    }
  }
  p {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  //signup and otp page
  .login {
    .head-text {
      font-size: 24px ;
      padding-top: 30px;
    }
    .sub-text {
      font-size: 16px ;
    }
    .signup-btn {
      font-size: 16px ;
    }
  }
  .heading {
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    color: #1c1b1a;
    font-family: $ralewayFont;
  }
  .swiper-pagination {
    padding: 1rem 0rem 1rem 0rem;
    position: static;
    text-align: left;
  }
  .title {
    font-size: 26px;
  }
  .view-all-text {
    font-size: 14px;
  }
  .home-banner {
    .swiper-pagination {
      padding: 1rem 0rem 1rem 0rem;
    }
  }
  
  .trending-title {
    font-size: 46px;
    i {
      font-size: 35px;
    }
    img {
      width: 40px;
    }
  }
  p {
    font-size: 14px;
  }
}
img {
  vertical-align: bottom;
}
