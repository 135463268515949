.slide-in-fwd-right {
	-webkit-animation: slide-in-fwd-right 0.4s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
	        animation: slide-in-fwd-right 0.4s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
}
/**
 * ----------------------------------------
 * animation slide-in-fwd-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
            transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
            transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.slide-right {
	-webkit-animation: slide-right 5s linear infinite alternate both;
					animation: slide-right 5s linear infinite alternate both;
}
/**
* ----------------------------------------
* animation slide-right
* ----------------------------------------
*/
@-webkit-keyframes slide-right {
0% {
	-webkit-transform: translateX(0);
					transform: translateX(0);
}
100% {
	-webkit-transform: translateX(100px);
					transform: translateX(100px);
}
}
@keyframes slide-right {
0% {
	-webkit-transform: translateX(0);
					transform: translateX(0);
}
100% {
	-webkit-transform: translateX(100px);
					transform: translateX(100px);
}
}




// faq css
.accordion {
  .card .card-header .btn-link-custom {
    .show_plus {
      display: none;
    }
    .show_close {
      display: block;
    }
  }
  .show_plus {
    width: 28px;
    //border: 2px solid #11aeea;
    color: $color-white;
    padding: 3px;
  }
  .show_close {
    width: 28px;
    color: $color-white;
    //border: 2px solid #11aeea;
    padding:11px 3px;
  }

  .card .card-header .btn-link-custom.collapsed {
    .show_plus {
      display: block;
    }
    .show_close {
      display: none;
    }
  }
  .accordian_card_header {
    display: flex;
    ///flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
.faq_cards {
  background: none;
  color: $color-white;
  border-bottom: 1px solid #fff !important;

  button {
    color: $color-white;
    background: none;
    border: none;
    font-family: $font-bold-text;
    font-size: 18px;
    line-height: 1.18;
    text-align: left;
  }
  .card-body {
    font-family: $font-light-text;
    font-size: 15px;
    line-height: 1.4;
		text-align: left;
  }
}
// show_plus
// show_close
