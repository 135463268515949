body {
  background-color: #fff;
  color: #000;
}

.close {
  opacity: 1;
}
.modal{
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 600000 !important;
}

.modal-content {
  padding: 10px;
  border-radius: 0px;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.modal-header {
  position: absolute;
  right: 10px;
  top: 16px;

  button {
    z-index: 2;
  }
}

// TODO: remove this class from all components wherever used, 
// .hoopr_popup{
//   overflow: hidden;
// }

.form_errors {
  color: #FF0000;
  font-size: 12px;
  display: block;
  overflow: hidden; 

}

.hoopr_page_laoder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(30 30 30 / 91%);
  z-index: 1099;

  img {
    width: 140px;
  }
}

.center {
  margin: 0 auto;
}

.tooltip {
  position: relative;
  opacity: unset !important;

  &__item {
    position: absolute;
    min-width: 100px;
    width: 400px;
    padding: 8px;
    visibility: hidden;
    opacity: 0;
    background: #fefdfd;
    transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
    color: black;
    border: 1px solid #cecece;
    border-radius: 3px;
    // font-weight: 500;
    box-shadow: 0 2px 1px #bcbcbc;
    z-index: 4;
    // &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   width: 0;
    //   height: 0;
    //   border-style: solid;
    // }
  }

  &__initiator {
    cursor: pointer;
    z-index: 5;
  }

  &[data-direction="left"] {
    .tooltip__initiator:hover~.tooltip__item {
      transform: translate3d(0, -50%, 0);
      visibility: visible;
      opacity: 1;
    }

    .tooltip__item {
      top: 50%;
      left: 2em;
      transform: translate3d(15px, -50%, 0);

      // &:after {
      //   top: 50%;
      //   right: -0.5em;
      //   transform: translate3d(0, -50%, 0);
      //   border-width: 0.5em 0 0.5em 0.5em;
      //   border-color: transparent transparent transparent #cecece;
      //   -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
      //   filter: drop-shadow(1px 2px 1px #bcbcbc);
      // }
      &:before {
        content: " ";
        position: absolute;
        background: #fefdfd;
        width: 10px;
        height: 10px;
        top: 42%;
        left: -5px;
        transform: rotate(45deg);
      }
    }
  }

  &[data-direction="bottom"] {
    .tooltip__initiator:hover~.tooltip__item {
      transform: translate3d(-50%, 0, 0);
      visibility: visible;
      opacity: 1;
    }

    .tooltip__item {
      top: calc(100% + 1em);
      left: 50%;
      transform: translate3d(-50%, -15px, 0);

      &:after {
        top: -0.5em;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        border-width: 0 0.5em 0.5em 0.5em;
        border-color: transparent transparent white transparent;
        -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
        filter: drop-shadow(1px -1px 1px #bcbcbc);
      }
    }
  }
}

/*-------------------------------------------------
        Input floating-label styles
---------------------------------------------------*/

.hoopr_form_fields {
  position: relative;
  margin-bottom: 32px;
  height: 50px;
  display: flex;
  align-items: end;
  justify-content: space-around;
  padding: 0 10px;
  background-color: #fff;
  select{
    padding: 0 16px;
  }
  select:has(option:checked)~.floating-label,
  select:focus~.floating-label,
  input:not(:focus):valid~.floating-label,
  input:focus~.floating-label {
    top: -28px;
    left: 25px;
    opacity: 1;
    font-size: 8px;
    font-weight: 500;
    line-height: 80px;
    color: #32353B;
  }

  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 25px;
    top: 18px;
    color: #32353B;
    transition: 0.2s ease-in-out all;
    
  }

  &.dark-label {
    .floating-label {
      font-size: 16px;
      font-weight: 500;
      opacity: 1;
    }
  }

  .inputText {
    width: 100%;
    height: 40px;
    border: none;
    background: none;
    padding: 0px 15px;
    font-size: 16px;
    font-weight: 700;
  }

  .error_info_highlight {
    font-size: 12px;
    color: red;
  }
}

.height-50 {
  height: 50px;
}

.hoopr_form_fields {
  margin-bottom: 10px;
}

.hoopr_form_field_group {
  margin-bottom: 20px;

  .form_errors {
    margin: 0;
  }
}

.top_up_field_values {
  width: 200px;
}

input:focus-within~.hoopr_form_fields {
  border: 1px solid #ff3567;
}

.hoopr_form_fields:hover .tooltiptext {
  visibility: visible;
}

// .tooltip {
//   i{
//     &:hover {}
//   }
// }

/*-------------------------------------------------
   HOOPR RADIO ACTION BUTTONS
-------------------------------------------------*/
.hoopr_radio_buttons {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.hoopr_radio_buttons input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.hoopr_radio_buttons label {
  display: inline-block;
  cursor: pointer;
  font-family: $font-light-text;
  font-size: 15px;
  border-radius: 4px 12px;
  border: solid 1px rgba(74, 74, 74, 0.26);
  width: 30%;
  // height: 55px;
  padding: 12px 17px;
  background-color: #1e1e1e;
  // border-top-right-radius: 22px;
  // border-bottom-left-radius: 18px;
  color: $color-white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hoopr_radio_buttons label:hover {
  //background-color: #dfd;
}

.hoopr_radio_buttons input[type="radio"]:focus+label {
  border: 1px solid $brand-pink-color;
  font-family: $font-semibold-text;
}

.sign-up-radio-btn input[type="radio"]:checked+label {
  background-color: $color-black;
}

.hoopr_radio_buttons input[type="radio"]:checked+label {
  font-family: $font-semibold-text;
  //background-color: #bfb;
  border-color: $brand-pink-color;

  i {
    color: $brand-pink-color;
  }
}

.radio_buttons_label {
  i {
    margin-right: 10px;
    font-size: 32px;
  }
}

.female-fa-icon {
  transform: rotate(45deg);
}

// audio player css
.audio__player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #222222;
  box-shadow: 0 -3.5px 18px 0 rgb(0 0 0 / 30%);
  padding: 11px 30px;
  display: flex;
  align-items: center;
}

.song_wave {
  width: 27%;

  wave {
    height: 30px !important;

    wave {
      border: 0 !important;
    }
  }
}

.mute_unmute_actions {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;

  &:hover {
    input {
      display: block;
    }
  }

  input {
    // display: none;
    // width: 60px;
    // position: absolute;
    // transform: rotate(271deg);
    // top: -38px;
    // left: -16px;
  }
}

.song_image {
  width: 84px;
  height: 84px;
  border-radius: 10px;

  // border-top-right-radius: 30px;
  // border-bottom-left-radius: 30px;
  img {
    width: 100%;
  }
}

.Song_theme_data {
  margin: 0 20px;
  width: 200px;

  p {
    font-family: $font-semibold-text;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.23px;
    text-align: left;
    color: #fff;
    margin: 0;
  }

  label {
    font-family: $font-light-text;
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.07px;
    text-align: left;
    color: #c1c1c1;
  }
}

.Player_buttons {
  display: flex;

  i {
    font-size: 20px !important;
    cursor: pointer;
  }

  .fa-play {
    font-size: 20px !important;
  }
}

.song_lenght_buttons {
  min-width: 125px;

  button {
    opacity: 0.25;
    border-radius: 2.5px;
    border: solid 0.5px #fff;
    background: none;
    font-family: $font-semibold-text;
    font-size: 14px;
    letter-spacing: 0.07px;
    text-align: left;
    color: #fff;
    margin-right: 10px;

    &.active {
      background-color: #fff;
      color: #000;
      opacity: unset;
    }
  }
}

/*===================================
    Custom checkbox
======================================*/
.hoopr_custom_checkbox {
  overflow: hidden;
  position: relative;
  padding: 10px;
}

.checklabel {
  display: block;
  border-radius: 2px;
  text-align: center;
  // padding: 10px 3em;
  padding: 12px 17px !important;
  cursor: pointer;
  font-family: $font-light-text;
  font-size: 15px;
  border-radius: 4px 12px;
  //border: solid 2px rgba(74, 74, 74, 0.26);
  //min-width: 34%;
  // height: 45px;
  // padding: 0 10px;
  background-color: #1e1e1e;
  // border-top-right-radius: 22px;
  // border-bottom-left-radius: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  //justify-content: center;
}

// .checklabel:hover {
// 	background:#ddd;
// }
.checkbox {
  position: absolute;
  right: 10px;
  display: none;
  top: 15px;
  width: 2.2em;
  height: 2.2em;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
  padding: 0;
  border-radius: 0;
  vertical-align: middle;
  transition: 0.3s ease;
  outline: 0;
}

// .checkbox:focus + label{
// 	border-color:blue;
// }
// .checkbox:active + label {
// 	background-color: #ddd;
// }
.checkbox:checked {
  background-position: 0 0;
}

.checkbox:checked+label {
  // border: 1px solid #ff3567;
  font-family: $font-semibold-text;
}

.form__conditions_fotter {
  font-family: $font-regular-text;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
  margin: 40px auto 0;

  .already_accont {
    span {
      text-decoration: underline;
      letter-spacing: 2px;
      cursor: pointer;
    }
  }

  .agreement_conditions {
    span {
      text-decoration: underline;
    }
  }
}

.dropdown_customised.show {
  display: block;
}

.dropdown_customised {
  transform: translate3d(0px, 51px, 0px) !important;
  will-change: unset !important;
  position: absolute !important;
  top: 89%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  width: 100%;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #2e2d2d;
  color: $color-white;
  // border: 1px solid #ccc;
  // border: 1px solid rgba(0,0,0,.15);
  border-radius: 10px;

  // box-shadow: 0 6px 12px rgba(0,0,0,.175);
  p {
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    margin: 0;

    // overflow: hidden;
    // text-overflow: ellipsis;
    &:hover {
      background-color: $color-white;
      color: #ff3567;
    }
  }
}

.custom__dropdown_menu {
  .arrow_marks {
    .fa-angle-down {
      display: block;
    }

    .fa-angle-up {
      display: none;
    }
  }
}

.custom__dropdown_menu.show {
  border: 1px solid #ff3567;

  .arrow_marks {
    .fa-angle-down {
      display: none;
    }

    .fa-angle-up {
      display: block;
    }
  }
}

// morque continus scroll;

.marquee-wrapper {
  //background:#2F394C;
  text-align: center;
  width: 100%;
}

.marquee-wrapper .container {
  overflow: hidden;
}

.marquee-inner span {
  float: left;
  width: 50%;
  overflow: hidden;
  height: 55px;
}

.marquee-wrapper .marquee-block {
  --total-marquee-items: 5;
  height: 100px;
  //width: calc(250px * (var(--total-marquee-items)));
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.marquee-inner {
  display: block;
  width: 200%;
  position: absolute;
}

.marquee-inner.to-left {
  animation: marqueeLeft 35s linear infinite;
}

.marquee-inner.to-right {
  animation: marqueeRight 35s linear infinite;
}

.marquee-item {
  width: 149px;
  height: 60px;
  display: inline-block;
  margin: 0 10px;
  float: left;
  transition: all 0.2s ease-out;

  //background:#00cc00;
  img {
    width: 80%;

    &:hover {
      transform: scale(1.1);
      opacity: unset;
    }
  }
}

@keyframes marqueeLeft {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@keyframes marqueeRight {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

// banner_word change
.word_change_container {
  position: relative;
}

.spin-word {
  font-size: 3em;
  font-weight: bold;
  opacity: 0;
  position: absolute;
  width: 100%;
  text-align: center;
}

@keyframes w1anim {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }
}

@keyframes w2anim {
  20% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }
}

@keyframes w3anim {
  40% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}

@keyframes w4anim {
  60% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }
}

@keyframes w5anim {
  80% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.spin1 {
  -webkit-animation: w1anim 20s infinite;
  animation: w1anim 20s infinite;
}

.spin2 {
  -webkit-animation: w2anim 20s infinite;
  animation: w2anim 20s infinite;
}

.spin3 {
  -webkit-animation: w3anim 20s infinite;
  animation: w3anim 20s infinite;
}

.spin4 {
  -webkit-animation: w4anim 20s infinite;
  animation: w4anim 20s infinite;
}

.spin5 {
  -webkit-animation: w5anim 20s infinite;
  animation: w5anim 20s infinite;
}

// landingpage_#D rotation
#slideshow {
  margin: 0 auto;
  // padding-top: 50px;
  height: 288px;
  width: 100%;
  //background-color: #6adecd;
  box-sizing: border-box;
}

.slideshow-title {
  font-family: "Allerta Stencil";
  font-size: 62px;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  margin-top: 25%;
  letter-spacing: 3px;
  font-weight: 300;
}

.sub-heading {
  padding-top: 50px;
  font-size: 18px;
}

.sub-heading-two {
  font-size: 15px;
}

.sub-heading-three {
  font-size: 13px;
}

.sub-heading-four {
  font-size: 11px;
}

.sub-heading-five {
  font-size: 9px;
}

.sub-heading-six {
  font-size: 7px;
}

.sub-heading-seven {
  font-size: 5px;
}

.sub-heading-eight {
  font-size: 3px;
}

.sub-heading-nine {
  font-size: 1px;
}

.entire-content {
  margin: auto;
  width: 190px;
  perspective: 1000px;
  position: relative;
  padding-top: 80px;
}

.content-carrousel {
  width: 100%;
  position: absolute;
  float: right;
  animation: rotar 80s infinite linear;
  transform-style: preserve-3d;
}

.content-carrousel:hover {
  animation-play-state: paused;
  cursor: pointer;
}

.content-carrousel figure {
  width: 100%;
  height: 120px;
  //border: 1px solid #3b444b;
  overflow: hidden;
  position: absolute;
}

.content-carrousel figure:nth-child(1) {
  transform: rotateY(0deg) translateZ(300px);
}

.content-carrousel figure:nth-child(2) {
  transform: rotateY(60deg) translateZ(300px);
}

.content-carrousel figure:nth-child(3) {
  transform: rotateY(120deg) translateZ(300px);
}

.content-carrousel figure:nth-child(4) {
  transform: rotateY(180deg) translateZ(300px);
}

.content-carrousel figure:nth-child(5) {
  transform: rotateY(240deg) translateZ(300px);
}

.content-carrousel figure:nth-child(6) {
  transform: rotateY(300deg) translateZ(300px);
}

.content-carrousel figure:nth-child(7) {
  transform: rotateY(360deg) translateZ(300px);
}

// .content-carrousel figure:nth-child(8) {
// 	transform: rotateY(280deg) translateZ(300px);
// } .content-carrousel figure:nth-child(9) {
// 	transform: rotateY(320deg) translateZ(300px);
// } .content-carrousel figure:nth-child(10) {
// 	transform: rotateY(360deg) translateZ(300px);
// }

.hoopr_artist_content-carrousel figure:nth-child(1) {
  transform: rotateY(0deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(2) {
  transform: rotateY(40deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(3) {
  transform: rotateY(80deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(4) {
  transform: rotateY(120deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(5) {
  transform: rotateY(160deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(6) {
  transform: rotateY(200deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(7) {
  transform: rotateY(240deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(8) {
  transform: rotateY(280deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(9) {
  transform: rotateY(320deg) translateZ(300px);
}

.hoopr_artist_content-carrousel figure:nth-child(10) {
  transform: rotateY(360deg) translateZ(300px);
}

.shadow {
  position: absolute;
  box-shadow: 0px 0px 20px 0px #000;
  border-radius: 5px;

  i {
    display: none;
    color: #ff3567;
    font-size: 12px;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 42%;
    top: 42%;
    background-color: #fff;
    border-radius: 50%;
  }

  &:hover {
    i {
      display: block;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

.content-carrousel img {
  image-rendering: auto;
  transition: all 300ms;
  width: 100%;
  height: 100%;
  border-top-right-radius: 30px;
  border: 0;
  border-bottom-left-radius: 30px;
}

.content-carrousel img:hover {
  transform: scale(1.2);
  transition: all 300ms;
}

@keyframes rotar {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.search_input_box {
  width: 29%;
  border-radius: 32px;
  background-color: #434343;
  height: 42px;
  align-items: center;
  padding: 2px 12px;
  margin-left: 29px;

  label {
    i {
      opacity: 0.6;
    }
  }

  input {
    height: 100%;
    width: 100%;
    background: none;
    border: none;
    color: #fff;
    padding: 0 10px;
    font-family: $font-regular-text;
    font-size: 20px;
    color: #fff;

    &::placeholder {
      opacity: 0.6;
    }
  }
}

@media screen and (max-width: 418px) {
  .hoopr_radio_buttons {
    display: flex;
    flex-direction: row;
    margin: 10px;
    //width: 370px;
  }

  .hoopr_radio_buttons label {
    font-size: 14px;
    width: 32%;
  }

  .marquee-item {
    height: 69px;
  }

  .marquee-inner {
    animation-duration: 6s !important;

    span {
      width: 100%;
      height: 80px;
    }
  }
}

.pagination__bar {
  display: flex;
  justify-content: space-between;

  .previous {
    p {
      color: #fff;
      font-family: $font-semibold-text;
      font-size: 18px;
    }
  }

  .next {
    p {
      color: #fff;
      font-family: $font-semibold-text;
      font-size: 18px;
    }
  }
}