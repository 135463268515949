/* ================================
     fonts Codes Variables
================================== */
$font-bold-text: Assistant-Bold;
$font-light-text: Assistant-Light;
$font-medium-text: Assistant-Medium;
$font-regular-text: Assistant-Regular;
$font-semibold-text: Assistant-SemiBold;
$font-italic-text: Assistant-Italic;

/* ================================
     Urbanist fonts Codes Variables
================================== */
$font-Urbanist-bold-text: Urbanist-Bold;
$font-Urbanist-light-text: Urbanist-Light;
$font-Urbanist-medium-text: Urbanist-Medium;
$font-Urbanist-regular-text: Urbanist-Regular;
$font-Urbanist-semibold-text: Urbanist-SemiBold;
$font-Urbanist-italic-text: Urbanist-Italic;
$font-Urbanist-black-text: Urbanist-Black;

/* ================================
     color Codes Variables
================================== */
$secondary-color:#000;
$brand-secondary-color: #4C898B;
$color-white: #fff;
$color-black: #000;
$theame-bg-color: #121212;
$brand-pink-color: #ff3567;
$color-grey: #787878;
$border-white: #787878;
$color-light-grey: #C3C3C3;
$landing-text-color: #CCCCCC;
$border-light: #6b6969;
$border-grey: #959595;
$font-cta: 'Assistant', sans-serif;
$color-mute: rgba(255,255,255,0.6);
$dropdown-bg: #1e1e1e;
$brand-pink-gradient: linear-gradient(to right, #ff7b7b, #ff3567);
$brand-secondary-color: #4C898B;
$color-green:#6AB47E;
$card-dark-grey: #1F1F1F;
$yellow: #FFD600;
$ralewayFont: 'Raleway', sans-serif;
// Common css
button:disabled {
  opacity: 0.2;
}
.color_brand_pink {
  color: $brand-pink-color !important;
}
.color_success {
  color: #24b724 !important;
}

.cursor {
  cursor: pointer;
}

.media_player_wave {
  wavesurfer {
    width: 100%;
  }
  ngx-wavesurfer {
    width: 100%;
  }
}

.word_change_container {
  
  label:after {
    //position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    line-height: 48px;
    left: 30%;
    top: 50%;
    animation: abomination1 15s infinite;
    animation-fill-mode: forwards;
  }
}

@keyframes abomination1 {
  0% {
    content: 'v';
  }
  4% {
    content: 'vi';
  }
  8% {
    content: 'vid';
  }
  12% {
    content: 'vide ';
  }
  16% {
    content: 'video';
  }
  20% {
    content: 'video';
  }
  24% {
    content: 'v';
  }
  28% {
    content: 'vl';
  }
  32% {
    content: 'vlo';
  }
  36% {
    content: 'vlog';
  }
  40% {
    content: 'vlogs';
  }
  44% {
    content: 'a';
  }
  48% {
    content: 'ad';
  }
  50% {
    content: 'ads';
  }
  54% {
    content: 'ads c';
  }
  56% {
    content: 'ads ca';
  }
  58% {
    content: 'ads cam';
  }
  60% {
    content: 'ads camp';
  }
  62% {
    content: 'ads campa';
  }
  64% {
    content: 'ads campai ';
  }
  66% {
    content: 'ads campaig';
  }
  68% {
    content: 'ads campaign';
  }
  70% {
    content: 'ads campaigns';
  }
  // 72% {
  //   content: "ads campangine";
  // }
  // 74% {
  //   content: "ads campanginer";
  // }
  76% {
    content: 'f';
  }
  78% {
    content: 'fi';
  }
  80% {
    content: 'fil';
  }
  83% {
    content: 'film';
  }
  85% {
    content: 'films';
  }
  88% {
    content: 'p';
  }
  90% {
    content: 'po';
  }
  92% {
    content: 'pod';
  }
  94% {
    content: 'podc';
  }
  96% {
    content: 'podca';
  }
  98% {
    content: 'podcas';
  }
  99% {
    content: 'podcast';
  }
  100% {
    content: 'podcasts';
  }
}

@mixin nowrap(){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin lineclamp($lines){
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  white-space: normal;

}